<template>
  <div id="page-tree-account-plans">

    <b-button
      variant="primary"
      class="mr-2"
      @click="addNode"
    >
      <feather-icon
        icon="PlusCircleIcon"
        class="mr-50"
      />
      <span class="align-middle">Adicionar Plano de Contas Principal</span>
    </b-button>

    <hr>
    <vue-tree-list
      :model="data"
      :default-expanded="false"
      default-tree-node-name="Novo Plano de Conta"
      default-leaf-node-name="new leaf"
      @change-name="onChangeName"
      @delete-node="onDel"
      @add-node="onAddNode"
      @drop="onDropNode"
    >
      <template v-slot:leafNameDisplay="slotProps">
        <span> {{ slotProps.model.name }} <!-- <span class="muted">#{{ slotProps.model.id }}</span> --> </span>
      </template>
      <feather-icon
        slot="addTreeNodeIcon"
        title="Adicionar"
        icon="PlusCircleIcon"
        class="mt-0 mr-1 cursor-pointer"
      />

      <feather-icon
        slot="editNodeIcon"
        title="Editar"
        icon="Edit2Icon"
        class="mt-0 mr-1 cursor-pointer"
        @click="node = { changed: false }"
      />

      <feather-icon
        slot="delNodeIcon"
        title="Excluir"
        icon="TrashIcon"
        class="mt-0 cursor-pointer"
      />

      <feather-icon
        slot="treeNodeIcon"
        icon="FolderIcon"
        class="mt-0 mr-1"
      />
      <span
        slot="addLeafNodeIcon"
        class="cursor-pointer"
      /> <!-- Vazio para que não mostre esta opção -->
      <span
        slot="leafNodeIcon"
        class="cursor-pointer"
      /> <!-- Vazio para que não mostre esta opção -->
    </vue-tree-list>
    <!-- <button @click="getNewTree">Get new tree</button>
    <pre>
      {{newTree}}
    </pre> -->

  </div>
</template>

<script>
import { VueTreeList, Tree, TreeNode } from 'vue-tree-list'
import { BButton } from 'bootstrap-vue'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  name: 'TreeAccountPlans',
  components: {
    VueTreeList,
    BButton,
  },

  props: {
    accountPlansProp: { type: Array, required: true },
  },

  setup() {
    // Use toast
    const toast = useToast()

    const showToast = (variant = 'success', icon = 'CheckIcon', title = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    }

    return {
      showToast,
    }
  },

  data() {
    return {
      popupDeleteConfirm: false,
      newTree: {},
      data: new Tree(this.accountPlansProp),
      node: null,
    }
  },

  methods: {
    addNode() {
      const node = new TreeNode({ name: 'Novo Plano de Contas', isLeaf: false })
      if (!this.data.children) this.data.children = []
      this.data.addChildren(node)
      this.store(node)
    },

    onAddNode(params) {
      this.store(params)
    },

    async store(params) {
      try {
        const res = await store.dispatch('store-account-plan/add', { name: params.name, pid: params.pid })
        // eslint-disable-next-line no-param-reassign
        params.id = res.data.id
        this.showToast()
      } catch (e) {
        let message = 'Erro ao salvar dados!'
        if (e.response.status === 401) message = 'Sua sessão expirou, faça novo login!'
        if (e.response.status === 412) message = e.response.data.message
        this.showToast('danger', 'AlertTriangleIcon', message, null)
      }
    },

    // COMO NÃO RECEBO O NODE EM PARAMS AO PRESSIONAR ENTER NA EDIÇÃO, PRECISEI FAZER UMA MANOBRA
    onChangeName(params) {
      if (params.node && !this.node.changed) {
        this.node = {
          id: params.node.id,
          name: params.oldName,
          pid: params.node.pid,
          changed: true,
        }
      }
      if (params.eventType === 'blur' && this.node.id) {
        if (this.node.name !== params.newName) {
          this.update(this.node, params)
        } else {
          this.showToast('success', 'CheckIcon', 'Nenhuma Alteração Detectada.', null)
        }
      } else if (params.eventType === 'blur') this.showToast('success', 'CheckIcon', 'Nenhuma Alteração Detectada.', null)
    },

    onDropNode({ node }) {
      this.update(node, { newName: node.name })
    },

    async update(oldNode, params) {
      try {
        await this.$store.dispatch('store-account-plan/update', { id: oldNode.id, name: params.newName, pid: oldNode.pid })
        this.showToast()
      } catch (e) {
        let message = 'Erro ao salvar dados!'
        if (e.response.status === 401) message = 'Sua sessão expirou, faça novo login!'
        if (e.response.status === 412) message = e.response.data.message
        this.showToast('danger', 'AlertTriangleIcon', message, null)
      }
    },

    onDel(node) {
      if (node.children && node.children.length) {
        this.showToast('warning', 'AlertTriangleIcon', 'Atenção', 'Este plano de conta possui subplanos (filhos), por isso não pode ser excluído! Remova todos os filhos primeiro.')
      } else {
        this.$bvModal
          .msgBoxConfirm(`Deseja Excluir o Plano de Contas ${node.plan}? Esta ação é irreversível e pode afetar relatórios, estatísticas e gráficos.`, {
            title: 'Por Favor Confirme esta Ação',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Sim',
            cancelTitle: 'Não',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) this.deleteData(node)
          })
      }
    },

    async deleteData(accountPlan) {
      try {
        await this.$store.dispatch('store-account-plan/delete', accountPlan.id)
        this.showToast('success', 'CheckIcon', 'Sucesso', 'Dados excluídos.')
        accountPlan.remove()
        this.popupDeleteConfirm = false
      } catch (e) {
        let message = 'Erro ao salvar dados!'
        if (e.response.status === 401) message = 'Sua sessão expirou, faça novo login!'
        if (e.response.status === 412) message = e.response.data.message
        this.showToast('danger', 'AlertTriangleIcon', message, null)
      }
    },

    // onClick(params) {
    //   console.log(params)
    // },

    // getNewTree () {
    //   const vm = this
    //   function _dfs (oldNode) {
    //     const newNode = {}

    //     for (const k in oldNode) {
    //       if (k !== 'children' && k !== 'parent') {
    //         newNode[k] = oldNode[k]
    //       }
    //     }

    //     if (oldNode.children && oldNode.children.length > 0) {
    //       newNode.children = []
    //       for (let i = 0, len = oldNode.children.length; i < len; i++) {
    //         newNode.children.push(_dfs(oldNode.children[i]))
    //       }
    //     }
    //     return newNode
    //   }

    //   vm.newTree = _dfs(vm.data)
    // }
  },
}
</script>
<!--
<style lang="less" rel="stylesheet/less">
  .vtl {
    .vtl-drag-disabled {
      background-color: #d0cfcf;
      &:hover {
        background-color: #d0cfcf;
      }
    }
    .vtl-disabled {
      background-color: #d0cfcf;
    }
  }
</style>
-->

<style scoped>
  .muted {
    color: gray;
    font-size: 80%;
  }
</style>
