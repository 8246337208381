<!-- =========================================================================================
    EXEMPLO DE ESTRUTURA DE ÁRVORE
    ----------------------------------------------------------------------------------------
      accountPlans: [
        {
          name: 'Node 1',
          id: 1,
          pid: 0,
          dragDisabled: true,
          addTreeNodeDisabled: true,
          addLeafNodeDisabled: true,
          editNodeDisabled: true,
          delNodeDisabled: true,
          children: [
            {
              name: 'Node 1-2',
              id: 2,
              isLeaf: true,
              pid: 1
            }
          ]
        },
        {
          name: 'Node 2',
          id: 3,
          pid: 0,
          disabled: true
        },
        {
          name: 'Node 3',
          id: 4,
          pid: 0
        }
      ]
========================================================================================== -->

<template>
  <div id="page-account-plans">
    <b-card>
      <tree-search-tree
        v-if="ready"
        :account-plans-prop="accountPlans"
      />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { computed, ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'

import TreeSearchTree from './TreeAccountPlans.vue'
// Store Module
import useStoreAccountPlans from './useStoreAccountPlans'

export default {
  components: {
    TreeSearchTree,
    BCard,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'store-account-plan'
    const ready = ref(false)

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, useStoreAccountPlans)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const accountPlans = computed(() => store.getters['store-account-plan/forTree'])

    const fetch = async () => {
      try {
        await store.dispatch('store-account-plan/fetch')
        ready.value = true
      } catch (e) {
        // console.log(e)
      }
    }
    fetch()

    return {
      ready,

      fetch,
      accountPlans,
    }
  },
}
</script>
